/* Style override */
.home-hero-segment {
  /* background-color: #F8CF91 !important;*/
}

.home-hero-segment .ui.large.top.fixed.menu {
  background-color: #F8EBD4 !important;
}

.home-hero-segment .ui.menu .item::before {
  background: none !important;
}

.home-hero-segment .ui.container .item {
  border-left: none !important;
}

.home-hero-segment .ui.secondary.inverted.pointing.menu {
  border-width: 0px !important;
}

/* .ui .card{
  background-color: #F8CF91 !important;
} */

.underlined {
  background-image: linear-gradient(transparent 0%, transparent 65%, #F8CF91 65%, #F8CF91 90%, transparent 90%, transparent);
  display: inline;
  font-size: 1.1em;
}

.underlined:after {
  content: '';
  display: block;
}

/* 
.animated {
  background-image: linear-gradient(transparent 0%, transparent 65%, #F8CF91 65%, #F8CF91 90%, transparent 90%, transparent);
  display: inline;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s background-size;
}
.animated:after {
  content: '';
  display: block;
}
.animated:hover {
  background-size: 100% 100%;
}
a {
  background-image: linear-gradient(transparent 0%, transparent 80%, turquoise 80%, turquoise 100%, transparent 100%, transparent);
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}
a:hover {
  background-image: linear-gradient(transparent 0%, transparent 80%, #F8CF91 80%, #F8CF91 100%, transparent 100%, transparent);
} */

a.yellow {
  color: #FBBD08 !important;
}

a.white {
  color: white !important;
}

.ui.header a {
  color: rgba(54, 48, 40, 0.95) !important;
}

.ui.header a.white {
  color: rgba(250, 250, 250, 0.95) !important;
}

.filters {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.two.wide.computer.six.wide.mobile.two.wide.tablet.column {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding: 0px !important;
  width: 9% !important;
  border: 0.5px solid rgba(54, 48, 40, 0.015);
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .two.wide.computer.six.wide.mobile.two.wide.tablet.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding: 0px !important;
    width: 33% !important;
    border: 0.5px solid rgba(54, 48, 40, 0.015);
  }
}

.filters .button {
  background-color: white !important;
  min-height: 78px !important;
  max-height: 78px !important;
  border-radius: 0 !important;
}

.filters .button.active {
  background-color: #2185D0 !important;
}

.filters .column .button:first {
  border-radius: 20px !important;
}

/* @media (prefers-color-scheme: dark) {
body{
  background-color:#151515 !important;
}
} */
.ui.tiny.statistics {
  margin: auto
}

.ui.tiny.statistics .statistic>.value.mobile-value {
  font-size: 1.1em !important
}

.heromobile {
  background-color: #000 !important;
}

.userprofile .ui.inverted.segment {
  margin: 0px;
  /* padding-top: 0px; */
  padding-bottom: 0px
}

.userprofile .ui.horizontal.inverted.divider {
  margin-top: 0px
}

.horizontal-scroll-container {
  padding-right: 0;
}

.horizontal-scroll-content {
  overflow: auto;
  white-space: nowrap;
  padding-left: 10px;
  margin-right: -1em;
  margin-left: -1em;
  padding-bottom: 1.5em;
}





html {
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

/* body{
  height: auto;
} */

@media only screen and (max-width: 767px) {
  .ui.container.no-horizontal-margin {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

/* @media (prefers-color-scheme: dark) { */
body {
  background-color: #121212 !important;
}

.ui.header {
  color: #E7E7E7 !important;
}

.home-hero-segment {
  background-color: #000 !important;
}

a.yellow {
  color: #CBCBCB !important;
}

.home-hero-segment .ui.large.top.fixed.menu {
  background-color: #000 !important;
}

a.white {
  color: white !important;
}

/* } */

.post-play-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.centered-floating {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  pointer-events: none;
}

.story-circle {
  /* border-radius: 50%; */
  min-height: 40px;
  min-width: 40px;
  padding: 1.5px;
  background-clip: content-box;
  /* support: IE9+ */
  background-color: #ccc;
}


/* work around to force padding 0px */
.five.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column.no-padding {
  padding: 0px !important;
}

.four.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column.no-padding {
  padding: 1px !important;
}

@media only screen and (max-width: 767px) {
  .ui.grid.stackable .row .ten.wide.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding: 0px !important;
    /* background-color: red; */
  }

  .four.wide.computer.eight.wide.mobile.eight.wide.tablet.column {
    padding: 0px !important;
  }

  .home-hero-mobile.ui.container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}